<template>
  <div class="h-100 common-datasource-tools outlier-modal">
    <!-- <b-modal size="xl" title="Formula Tool" centered ok-only :hide-footer="true" :ok-title="$t('save')" ref="outlierModal" :modal-class="'outlier-modal'" :body-class="'custom-body'" button-size="sm" @show="resetState">
    <template #modal-header> -->
    <div class="tools-header">
      <b-form-group class="mb-0 mr-1" style="min-width: 300px">
        <TimeseriesSelectByLocation v-model="datasourceSelected" :selected="selected" @getSelected="setSelect" mode="multiple" />
      </b-form-group>
      <b-form-group class="mb-0" :title="$t('option_chart')">
        <label class="toolbar-title text-primary" style="font-size: 10px">{{ $t('option_chart') }}</label>
        <div class="d-flex model-actions d-flex align-center align-items-center justify-content-center">
          <b-media-aside>
            <b-button class="model-actions_item position-relative mr-0" style="width: 35px; height: 35px" rounded variant="light-primary" size="sm">
              <feather-icon icon="SettingsIcon" size="18" />
              <div class="ml-advanced-config-container oulier-modal-settings-container" :class="{ 'dark-layout': isDark }">
                <div class="ml-advanced-config-main" :style="{ minHeight: `170px`,maxWidth:'350px' }">
                  <b-row class="mb-1">
                    <b-col cols="6">
                      <div class="label-form-group">
                        <family-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /> <span>{{ $t('font_family') }}</span>
                      </div>
                      <b-form-group :label="``">
                        <vue-select :options="chartFontFamilyChoices" v-model="chartFontFamily" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <div class="label-form-group">
                        <size-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /><span>{{ $t('text_size') }}</span>
                      </div>
                      <b-form-group :label="``">
                        <b-form-input v-model="chartTextSize" :placeholder="$t('text_size')" type="number" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <div class="label-form-group">
                        <color-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /><span>{{ $t('text_color') }}</span>
                      </div>
                      <b-form-group :label="``">
                        <ColorPicker v-model="chartTextColor" :placeholder="$t('text_color')" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-button>
          </b-media-aside>
        </div>
      </b-form-group>
      <b-form-group class="mb-0 b-form-filename">
        <label class="text-primary tool-custom-label">{{ $t('file_name') }}</label>
        <b-form-input :placeholder="$t('file_name')" v-model.trim="filename" size="sm" style="height: 28px" />
      </b-form-group>
      <b-form-group class="ml-auto mb-0">
        <b-button variant="success" size="sm" style="padding: 14px 28px" :disabled="!datasource || !filename" @click="save">{{ $t('save') }}</b-button>
      </b-form-group>
    </div>
    <!-- <button type="button" class="close" @click="close">&times;</button>
    </template> -->
    <div class="tools-body">
      <div class="d-flex mb-1">
        <b-form-group class="mb-0 mr-1">
          <label class="text-primary tool-custom-label"> {{ $t('min') }}</label>
          <b-form-input :placeholder="$t('min')" @change="drawLimitValueChart()" v-model="minValue" size="sm" type="number" style="height: 40px" />
        </b-form-group>
        <b-form-group class="mb-0 mr-1">
          <label class="text-primary tool-custom-label"> {{ $t('max') }}</label>
          <b-form-input :placeholder="$t('max')" @change="drawLimitValueChart()" v-model="maxValue" size="sm" type="number" style="height: 40px" />
        </b-form-group>
        <b-form-group class="mb-0 mr-1">
          <label class="text-primary tool-custom-label"> {{ $t('type') }}</label>
          <RadioGroup :name="`outlier-modal-model`" label="Type Outlier Removal" :hiddenLable="true" :options="actions" v-model="action" />
        </b-form-group>
      </div>

      <div class="d-flex">
        <div class="chart-timeseries" style="height: calc(100vh - 270px); overflow: hidden; width: 100%">
          <b-overlay class="timeseries-container" :show="showOverlay" variant="transparent" opacity="0.85" blur="2px" rounded="sm">
            <TimeseriesChart :chartFontOutlier="chartFontOutlier" ref="chart" :chart="chart" :minValue="minValue" :maxValue="maxValue" :datesSelect="datesSelect" />
          </b-overlay>
        </div>
      </div>
    </div>
  </div>

  <!-- </b-modal> -->
</template>

<script>
const _ = require('lodash');
import MathMixin from '@/mixins/MathMixin.js';
import ScaleMixin from '@/mixins/ScaleMixin';
import DatatableBody from '../tools/DatatableBody.vue';
import TimeseriesSelectByLocation from './TimeseriesSelectByLocation.vue';
import FileMixin from '@/mixins/FileMixin.js';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import { v4 as uuidv4 } from 'uuid';
import { compile, round } from 'mathjs';
import { scaleNormalize, scaleStandardize } from '@/utilities/NumberUtility.js';
import { ThemeConfig } from '@/mixins/ThemeMixin.js';
import RadioGroup from '../../../../../common/RadioGroup.vue';
import TimeseriesChart from './TimeseriesChart.vue';
import { ACTION_LOG } from '@/constants/actionLog';
import ColorPicker from '../../../../../common/ColorPicker.vue';

// icon
import FamilyIcon from '/static/images/layer-config/prepend/font-family.svg';
import ColorIcon from '/static/images/layer-config/prepend/font-color.svg';
import SizeIcon from '/static/images/layer-config/prepend/font-size.svg';

export default {
  components: { DatatableBody, TimeseriesSelectByLocation, ToastificationContent, RadioGroup, TimeseriesChart, ColorPicker, FamilyIcon, ColorIcon, SizeIcon },
  mixins: [ScaleMixin, ThemeConfig, FileMixin, MathMixin],
  mounted() {
    this.rows = [];
  },
  data() {
    return {
      datasourceSelected: [],
      minValue: null,
      maxValue: null,
      action: 'original',
      chart: { data: [], layout: {} },
      showOverlay: false,
      selected: [],
      columns: [],
      filename: '',
      maths: [],
      addedColumn: [],
      error: null,
      itemFilterLength: 0,
      itemLength: 0,
      filteredData: [],
      dataFilterStore: [],
      updateDatatable: 0,
      datasourceType: '',
      datesSelect: [],
      chartFontFamilyChoices: ['Noto Sans JP', 'Calibri', 'Sans Serif', 'Serif', 'Cursive', 'Monospace'],
    };
  },
  computed: {
    actions() {
      return [
        { text: this.$t('original'), value: 'original' },
        { text: this.$t('min') + '-' + this.$t('max'), value: 'fill_min_max' },
        { text: this.$t('zero'), value: 'fill_zero' },
        { text: this.$t('remove'), value: 'fill_na' },
      ];
    },
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    options() {
      return this.datasources.filter((d) => d.type === 'timeseries').map((d) => ({ value: d.id, label: d.name }));
    },
    datasource() {
      let datasource = this.datasources.find((d) => d.id === this.selected);
      if (!datasource) return null;
      return { ...datasource, ...this.$db[datasource.id] };
    },
    datasourceTools() {
      return this.$store.state.map.datasourceTools;
    },
    chartFontFamily: {
      get() {
        try {
          return this.$store.state.tabs['datasouce_tools'].outlier.chartFontFamily;
        } catch {
          return this.$store.state.settings.chartFontFamily;
        }
      },
      set(chartFontFamily) {
        this.$store.commit(`tabs/SET_DATASOURCE_TOOLS_OUILIER`, { chartFontFamily });
      },
    },
    chartTextSize: {
      get() {
        try {
          return this.$store.state.tabs['datasouce_tools'].outlier.chartTextSize;
        } catch {
          return this.$store.state.settings.chartTextSize;
        }
      },
      set(chartTextSize) {
        this.$store.commit(`tabs/SET_DATASOURCE_TOOLS_OUILIER`, { chartTextSize });
      },
    },
    chartTextColor: {
      get() {
        try {
          return this.$store.state.tabs['datasouce_tools'].outlier.chartTextColor;
        } catch {
          return this.$store.state.settings.chartTextColor;
        }
      },
      set(chartTextColor) {
        this.$store.commit(`tabs/SET_DATASOURCE_TOOLS_OUILIER`, { chartTextColor });
      },
    },
    chartFontOutlier() {
      let font = {};
      try {
        if (this.chartFontFamily) {
          font.family = this.chartFontFamily;
        }
        if (this.chartTextSize) {
          font.size = this.chartTextSize;
        }
        if (this.chartTextColor) {
          font.color = this.chartTextColor;
        }
      } catch {}
      return font;
    },
  },
  watch: {
    datasourceSelected() {
      let idDatasource = null;
      if (this.datasourceSelected.length !== 0) {
        idDatasource = this.datasourceSelected[0].datasource;
      }
      this.datesSelect = this.$db[idDatasource] ? this.$db[idDatasource].dates : [];
      this.setChart();
    },
    async action() {
      this.setChart();
      await this.init();
    },

    async selected() {
      this.datasourceSelected = [];
      await this.init();
      this.updateDatatable++;
    },
    datasourceTools() {
      this.resetState();
    },
  },
  methods: {
    async drawLimitValueChart() {
      this.setChart();
      if (this.action !== 'original' && this.minValue && this.maxValue) {
        await this.init();
      }
    },
    setSelect(value) {
      this.selected = value;
    },
    async setChart() {
      let traces = [];
      let arrDash = ['solid', 'dashdot', 'dot', 'solid'];
      let arrayMin = [];
      let arrayMax = [];
      try {
        let traceCount = 0;
        let count = 0;
        let countLine = 0;
        const minDate = this.datesSelect[0];
        const maxDate = this.datesSelect[this.datesSelect.length - 1];
        const datasourceGrouped = _.groupBy(this.datasourceSelected, (d) => d.datasource);

        for (const datasourceId in datasourceGrouped) {
          const locationsItems = _.map(datasourceGrouped[datasourceId], (data) => {
            return data.location + '-' + data.item;
          });
          const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, null);
          const x = this.datesSelect;

          for (const locationItem of locationsItems) {
            countLine = Math.floor(count / 10);
            if (countLine > 3) {
              count = 0;
              countLine = 0;
            }
            // const [location, item] = splitByLastIndex(locationItem, '-');
            const name = locationItem.replace('-', ' - ');
            // const visible = this.selectedGroup && Object.keys(this.selectedGroup).length !== 0 && !this.selectedGroup.items.includes(location) ? 'legendonly' : true;
            const y = records[locationItem];
            if (this.action !== 'original' && this.minValue && this.maxValue) {
              if (this.action === 'fill_min_max') {
                for (let i = 0; i < y.length; i++) {
                  if (!this.isNaValue(y[i])) {
                    if (y[i] > this.maxValue) y[i] = this.maxValue;
                    else if (y[i] < this.minValue) y[i] = this.minValue;
                  }
                }
              }
              if (this.action === 'fill_zero') {
                for (let i = 0; i < y.length; i++) {
                  if (!this.isNaValue(y[i])) {
                    if (y[i] > this.maxValue || y[i] < this.minValue) y[i] = 0;
                  }
                }
              }
              if (this.action === 'fill_na') {
                for (let i = 0; i < y.length; i++) {
                  if (!this.isNaValue(y[i])) {
                    if (y[i] > this.maxValue || y[i] < this.minValue) y[i] = null;
                  }
                }
              }
            }
            traces.push({
              name: name,
              x: x,
              y: y,
              type: 'scattergl',
              mode: 'lines',
              line: { dash: arrDash[countLine] },
            });
            traceCount++;
            count++;
          }
        }
      } catch (err) {
        console.error(err);
      }
      this.chart = {
        // min: Math.min(...arrayMin),
        // max: Math.max(...arrayMax),
        data: traces,
        layout: {
          showlegend: false,
        },
      };
    },
    // open() {
    //   this.$refs.outlierModal.show();
    // },
    // close() {
    //   this.$refs.outlierModal.hide();
    // },
    resetState() {
      this.selected = null;
      this.columns = [];
      this.rows = [];
      this.maths = [];
      this.addedColumn = [];
      this.error = null;
      this.filename = '';
      this.datasourceSelected = [];
      this.minValue = null;
      this.maxValue = null;
      this.action = 'original';
      this.showOverlay = false;
      this.chart = { data: [], layout: {} };
    },
    transpose(matrix) {
      return matrix[0].map((_, columnIndex) => matrix.map((row) => row && row[columnIndex] != null ? row[columnIndex] : null));
    },
    async init() {
      if (!this.datasource) {
        this.columns = [];
        this.rows = [];
        this.itemFilterLength = 0;
        this.itemLength = 0;
        this.filteredData = [];
        this.dataFilterStore = [];
        this.updateDatatable = 0;
        return;
      }
      if (this.datasource.type === 'timeseries') {
        let datasource = this.$db[this.datasource.id];
        let items = this.datasource.items;
        let columns = [{ label: datasource.header, field: 'date' }];
        let rowsOriginal = {};
        let rowsConvert = {};
        rowsOriginal = await this.selectAll(this.datasource.id);
        rowsConvert['dates'] = _.cloneDeep(rowsOriginal['dates']);
        for (const key in datasource.columns) {
          let [location, item] = key.split('*');
          rowsConvert[key.replace('*', '-')] = _.cloneDeep(rowsOriginal[key.replace('*', '-')]);
          let filterLocation = this.datasourceSelected.filter((loc) => loc.location === location && loc.item === item);
          if (filterLocation && filterLocation.length !== 0) {
            if (this.action !== 'original' && this.minValue && this.maxValue) {
              if (this.action === 'fill_min_max') {
                for (let i = 0; i < rowsConvert[key.replace('*', '-')].length; i++) {
                  let isCheckNA = false;
                  try {
                    isCheckNA = this.isNaValue(rowsConvert[key.replace('*', '-')][i]);
                  } catch {}
                  if (!isCheckNA) {
                    if (rowsConvert[key.replace('*', '-')][i] > this.maxValue) rowsConvert[key.replace('*', '-')][i] = this.maxValue != null ? Number(this.maxValue) : this.maxValue;
                    else if (rowsConvert[key.replace('*', '-')][i] < this.minValue) rowsConvert[key.replace('*', '-')][i] = this.minValue != null ? Number(this.minValue) : this.minValue;
                  }
                }
              }
              if (this.action === 'fill_zero') {
                for (let i = 0; i < rowsConvert[key.replace('*', '-')].length; i++) {
                  let isCheckNA = false;
                  try {
                    isCheckNA = this.isNaValue(rowsConvert[key.replace('*', '-')][i]);
                  } catch {}
                  if (!isCheckNA) {
                    if (rowsConvert[key.replace('*', '-')][i] > this.maxValue || rowsConvert[key.replace('*', '-')][i] < this.minValue) rowsConvert[key.replace('*', '-')][i] = 0;
                  }
                }
              }
              if (this.action === 'fill_na') {
                for (let i = 0; i < rowsConvert[key.replace('*', '-')].length; i++) {
                  let isCheckNA = false;
                  try {
                    isCheckNA = this.isNaValue(rowsConvert[key.replace('*', '-')][i]);
                  } catch {}
                  if (!isCheckNA) {
                    if (rowsConvert[key.replace('*', '-')][i] > this.maxValue || rowsConvert[key.replace('*', '-')][i] < this.minValue) rowsConvert[key.replace('*', '-')][i] = null;
                  }
                }
              }
            }
          }
          columns.push({ label: datasource.header.length === 3 ? [location, item, datasource.columns[key].unit] : [location, datasource.columns[key].autoGroup, item, datasource.columns[key].unit], field: key, type: 'number' });
        }
        this.columns = columns;
        let lengDate = rowsConvert['dates'].length;
        this.rows = this.transpose(Object.values(rowsConvert));
        this.rows = this.rows.splice(0, lengDate);

        this.itemLength = this.rows.length;
        this.datasourceType = 'timeseries';
      }
    },
    async save() {
      const GROUP_IDX = this.columns[0].label.findIndex((c) => c === 'GROUP');
      const ITEM_IDX = this.columns[0].label.findIndex((c) => c === 'ITEM');
      const UNIT_IDX = this.columns[0].label.findIndex((c) => c === 'UNIT');
      // validate column name before save
      for (let i = 0; i < this.columns.length; i++) {
        if (!this.columns[i].label[0] || !this.columns[i].label[ITEM_IDX]) {
          this.$toast({ component: ToastificationContent, props: { title: 'Save failed', text: 'Empty column name', icon: 'SlashIcon', variant: 'danger' } });
          return;
        }
      }

      // save to current datasource
      // convert array of row object to 1 datasource object and add to store
      const id = uuidv4();
      const name = this.filename + (this.filename.endsWith('.csv') ? '' : '.csv');
      const type = this.datasource.type;
      const dates = this.rows.map((r) => r[0]);
      let columns = {};
      let autoGroups = {};
      let tmpGroup = []; // temp variable

      this.columns.forEach((column, index) => {
        if (column.field === 'date') return;
        let field = column.deleteable ? `${column.label[0]}*${column.label[ITEM_IDX]}` : column.field;
        columns[field] = { nullCount: 0, autoGroup: column.label[GROUP_IDX], unit: column.label[UNIT_IDX] };
        tmpGroup = GROUP_IDX !== -1 ? this.getGroupName(column.label[GROUP_IDX]) : [];
        tmpGroup.map((grName) => {
          if (!grName) return;
          if (!autoGroups[grName]) autoGroups[grName] = [];
          autoGroups[grName].push(field);
        });
      });
      const encoding = this.datasource.encoding;
      const storage_mode = 'RAM';
      let data = {};
      const keysCol = Object.keys(columns);
      for (let i = 0; i < keysCol.length; i++) {
        for (let j = 0; j < this.rows.length; j++) {
          if (!data[keysCol[i].replace('*', '-')]) data[keysCol[i].replace('*', '-')] = [];
          data[keysCol[i].replace('*', '-')].push(this.rows[j][i + 1]);
        }
      }
      let itemArray = [...new Set(this.columns.slice(1).map((column) => column.label[ITEM_IDX]))];
      let items = {};
      itemArray.forEach((item) => {
        if (!item) return;
        let obj = { min: Infinity, max: -Infinity, unit: '' };
        let groupItem = Object.keys(data)
          .filter((key) => key.endsWith(`-${item}`))
          .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {});
        obj.min = _.min([].concat(...Object.values(groupItem)));
        obj.max = _.max([].concat(...Object.values(groupItem)));
        // unit
        let index = this.columns.findIndex((column) => column.label[ITEM_IDX] === item);
        obj.unit = this.columns[index].label[UNIT_IDX];
        items[item] = obj;
      });
      const locations = this.datasource.locations;
      let datasourceObject = { id, name, type, dates, columns, encoding, locations, items, storage_mode, data, csvData: this.rows, header: [...this.columns[0].label], autoGroups };
      await this.storeData(datasourceObject);
      //CREATE OBJ FILE
      const file = await this.createObjectFile(datasourceObject);
      //SAVE TO TEMP FILE
      datasourceObject.file = file;
      datasourceObject.size = file.size;
      //UPDATE DATASOURCE
      await this.addDatasource(datasourceObject);
      this.updateDatatable++;
      let messageTitle = 'create_from_outlier';
      let message = this.$t(messageTitle, { move: name });
      this.log.info({ message, id: ACTION_LOG[7].id, messageTitle, move: name });
      // this.log.info({ message: `Create and add datasource '${name}' from datasource tool [Outlier]`, id: ACTION_LOG[7].id });
      // this.close();
    },
    getGroupName(str) {
      return str.toString().split('|');
    },
  },
};
</script>
<style scoped lang="scss">
.common-datasource-tools.outlier-modal .tools-body {
  height: calc(100% - 90px) !important;
}
.common-datasource-tools .tools-header{
  align-items: flex-end;
}

</style>
<style lang="scss">
.b-form-filename {
  & > div {
    input {
      height: 35px !important;
    }
  }
}
.tool-custom-radio label {
  line-height: 14px !important;
  padding: 6px 14px !important;
}

.tool-custom-label {
  display: block;
  line-height: 10px;
  margin-bottom: 3px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
}

.outlier-modal .calculator {
  width: 370px;
  flex-shrink: 0;
}

.outlier-modal .calculation-area {
  position: relative;
  padding: 5px;
  margin-bottom: 2rem;
  line-height: 1;
  min-height: 150px;
  border-radius: 0.357rem;
  border: 1px solid transparent;
}

.outlier-modal .calculation-area > span {
  display: inline-block;
  padding: 4px;
  vertical-align: middle;
}

.outlier-modal .calculation-area select {
  border: none;
  outline: none;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.358rem;
}

.outlier-modal .calculation-area .add-operand {
  border: none;
  outline: none;
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.358rem;
}

.outlier-modal .calculation-area .error-message {
  position: absolute;
  top: 100%;
  left: 0;
  line-height: 1rem;
  font-size: 12px;
}

.operation-area {
  display: flex;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: normal;
}

.operation-area button {
  min-width: 50px;
  margin-right: 1rem;
  height: 50px;
  font-size: 16px;
  font-weight: normal;
  padding: 7px;
}

.operation-area button:last-child {
  margin-right: 0;
}

.operation-area .btn-flat-danger {
  background-color: rgba(234, 84, 85, 0.12);
}

.operation-area .btn-flat-success {
  background-color: rgba(40, 199, 111, 0.12);
}

.operation-area .btn-flat-secondary {
  background-color: rgba(130, 134, 139, 0.12);
}

.calculate-button {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.calculate-button button {
  padding: 10px;
  margin: 1rem 0;
  width: 72px;
  font-size: 40px;
}

.dark-layout .outlier-modal .calculation-area {
  border-color: #404656;
}
/* .outlier-modal .tools-body{    height: calc(100% - 65px);

} */

.ml-advanced-config-main {
  background-color: white;
  border-radius: 7px;
  padding: 10px;
}
.dark-layout .ml-advanced-config-main {
  background-color: #283046;
}

.oulier-modal-settings-container {
  cursor: initial;
  position: absolute;
  left: -155px;
  top: 15px;
  transform: scale(0);
  opacity: 0;
  transform-origin: 150px 0;
  z-index: 1;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
  padding: 10px;
  width: 100%;
  margin-top: 40px;
  transition: transform 0.4s, opacity 0.4s;
  backdrop-filter: blur(2px);
  background-color: #fff;
  width: 350px;
  z-index: 11;
  backdrop-filter: blur(2px);
  background-color: rgba(57, 123, 255, 0.12) !important;
}
.ml-advanced-config-container.dark-layout {
  background-color: #283046;
}
.ml-advanced-config-container .ml-slider {
  margin-bottom: 30px;
}

.outlier-modal .model-actions .b-avatar:not(.model-icon) {
  width: 100% !important;
  padding: 7px;
}
.outlier-modal .model-actions .model-actions_item {
  // color: #397bff;
  padding: 7px;
  text-align: start;
  border: 1px solid rgba(115, 103, 240, 0.24);
  border-radius: 0.357rem;
  padding: 4px;
  display: flex;
  position: relative;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 50px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outlier-modal .model-actions .model-actions_item .spinner-model {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 12px;
  height: 12px;
}
.outlier-modal .model-actions .model-actions_item .toolbar-title {
  margin-bottom: 4px;
}
.outlier-modal .model-actions .model-actions_item:focus .ml-advanced-config-container,
.outlier-modal .model-actions .model-actions_item:focus-within .ml-advanced-config-container,
.outlier-modal .model-actions .model-actions_item .ml-advanced-config-container:hover {
  transform: scale(1);
  opacity: 1;
}
.outlier-modal .model-actions .b-avatar .action-title {
  text-transform: uppercase;
  margin-left: 7px;
}
.label-form-group {
  padding-bottom: calc(0.438rem + 1px);
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #808080;
}
</style>
